import React from "react";
import { AUTH_PREFIX_PATH, APP_PREFIX_PATH } from "configs/AppConfig";

export const publicRoutes = [
  {
    key: "login",
    path: `${AUTH_PREFIX_PATH}/login`,
    component: React.lazy(() =>
      import("views/auth-views/authentication/login")
    ),
  },
  {
    key: "set-password",
    path: `${AUTH_PREFIX_PATH}/setPassword`,
    component: React.lazy(() =>
      import("views/auth-views/authentication/set-password")
    ),
  },
  {
    key: "forgot-password",
    path: `${AUTH_PREFIX_PATH}/forgotPassword`,
    component: React.lazy(() =>
      import("views/auth-views/authentication/forgot-password")
    ),
  },
  {
    key: "verify-2fa",
    path: `${AUTH_PREFIX_PATH}/verify-2fa`,
    component: React.lazy(() =>
      import("views/auth-views/authentication/2fa/2fa-page")
    ),
  },
];

export const protectedRoutes = [
  {
    key: "default",
    path: `${APP_PREFIX_PATH}/dashboard`,
    component: React.lazy(() => import("views/app-views/dashboards/default")),
    access: ["Admin"],
  },
  {
    key: "users",
    path: `${APP_PREFIX_PATH}/users`,
    component: React.lazy(() => import("../pages/Users")),
    access: ["Admin"],
  },
  {
    key: "user-details",
    path: `${APP_PREFIX_PATH}/users/profile`,
    component: React.lazy(() => import("../pages/UserProfile")),
    access: ["Admin", "Client"],
  },
  {
    key: "user-settings",
    path: `${APP_PREFIX_PATH}/users/settings`,
    component: React.lazy(() => import("../pages/UserSettings")),
    access: ["Admin", "Client", "Trader"],
  },
  {
    key: "user-new",
    path: `${APP_PREFIX_PATH}/users/new`,
    component: React.lazy(() => import("../pages/UserAdd")),
    access: ["Admin"],
  },
  {
    key: "user-edit",
    path: `${APP_PREFIX_PATH}/users/edit/:id`,
    component: React.lazy(() => import("../pages/UserEdit")),
    access: ["Admin"],
  },
  {
    key: "login-history",
    path: `${APP_PREFIX_PATH}/login-history`,
    component: React.lazy(() => import("../pages/LoginHistory")),
    access: ["Admin"],
  },
  {
    key: "settings",
    path: `${APP_PREFIX_PATH}/settings`,
    component: React.lazy(() => import("../pages/Settings")),
    access: ["Admin"],
  },
  {
    key: "projects",
    path: `${APP_PREFIX_PATH}/projects`,
    component: React.lazy(() => import("../pages/Projects")),
    access: ["Admin"],
  },
  {
    key: "project-new",
    path: `${APP_PREFIX_PATH}/project-new`,
    component: React.lazy(() => import("../pages/ProjectAdd")),
    access: ["Admin"],
  },
  {
    key: "project-edit",
    path: `${APP_PREFIX_PATH}/project-edit/:projectId`,
    component: React.lazy(() => import("../pages/ProjectEdit")),
    access: ["Admin"],
  },
  {
    key: "slides",
    path: `${APP_PREFIX_PATH}/slides`,
    component: React.lazy(() => import("../pages/Slides")),
    access: ["Admin"],
  },
  {
    key: "slide-new",
    path: `${APP_PREFIX_PATH}/slide-new`,
    component: React.lazy(() => import("../pages/SlideAdd")),
    access: ["Admin"],
  },
  {
    key: "slide-edit",
    path: `${APP_PREFIX_PATH}/slide-edit/:slideId`,
    component: React.lazy(() => import("../pages/SlideEdit")),
    access: ["Admin"],
  },
  {
    key: "frontend-content",
    path: `${APP_PREFIX_PATH}/frontend-content`,
    component: React.lazy(() => import("../pages/FrontendContent")),
    access: ["Admin"],
  },
];
